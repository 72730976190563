import { createSlice } from "@reduxjs/toolkit";

export const chatSlice = createSlice({
  name: "chat",
  initialState: {
    notifications: 0,
    greenScoutsNotifications: 0,
  },
  reducers: {
    updateNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    updateGreenScoutsNotifications: (state, action) => {
      state.greenScoutsNotifications = action.payload;
    },
  },
});

const { reducer, actions } = chatSlice;
export const { updateNotifications, updateGreenScoutsNotifications } = actions;
export default reducer;
