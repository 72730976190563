import {
  postResource,
  getResource,
  putResource,
  deleteResource,
} from "./services";

/* Start admin */
export const signinAdmin = (data, onSuccess, onFail) => {
  const path = "api/Admin/Login?lang=en";
  postResource(path, data, onSuccess, onFail);
};
export function getAdminsApi(onSuccess, onFail) {
  const path = "api/Admin/GetScrappersAdmins";
  getResource(path, onSuccess, onFail);
}
export function addAdminApi(data, onSuccess, onFail) {
  const path = "api/Admin/AddAdmin";
  postResource(path, data, onSuccess, onFail);
}
export function editAdmin(data, onSuccess, onFail) {
  const path = `api/Admin/EditAdmin`;
  putResource(path, data, onSuccess, onFail);
}
export function getAdminRoles(onSuccess, onFail) {
  const path = "api/Admin/GetBigScrappersRoles";
  getResource(path, onSuccess, onFail);
}
export function checkAdminStatus(onSuccess, onFail) {
  const path = "api/Admin/CheckAdminStatus";
  getResource(path, onSuccess, onFail);
}
/* End admin */

/* Start dealers */
export function getDealersApi(params, onSuccess, onFail) {
  // const path = "api/User/GetScraptionUsers";
  const path = `api/User/GetScraptionUsersV2?perPage=${params.perPage}&pageNumber=${params.currentPage}&fullName=${params.fullName}&mobile=${params.mobile}&isMerchant=${params.isMerchant}&deservedAmount=${params.deservedAmount}&isExport=${params.isExport || false}`;
  getResource(path, onSuccess, onFail);
}
export function getDealerBalanceApi(dealerId, onSuccess, onFail) {
  const path = `api/Dealer/DealerBalanceForAdmin?dealerId=${dealerId}&language=en`;
  getResource(path, onSuccess, onFail);
}
export function updateMerchantStatusOfScrapper(data, onSuccess, onFail) {
  const path = `api/Dealer/UpdateDealerIsMerchant?language=en`;
  putResource(path, data, onSuccess, onFail);
}
/* End dealers */

/* Start items */
export const addItemApi = (data, onSuccess, onFail) => {
  const path = "api/Item/ScraptionItem?lang=en";
  postResource(path, data, onSuccess, onFail);
};
export const editItemApi = (data, onSuccess, onFail) => {
  const path = "api/Item/ScraptionItem?lang=en";
  putResource(path, data, onSuccess, onFail);
};
export const deleteItemApi = ({ itemId }, onSuccess, onFail) => {
  const path = `api/Item/ScraptionItem?lang=en&itemId=${itemId}`;
  deleteResource(path, onSuccess, onFail);
};
export function getAllUnitsApi(onSuccess, onFail) {
  const path = "api/Item/Units?language=en";
  getResource(path, onSuccess, onFail);
}
export function getAllItemsApi(onSuccess, onFail) {
  const path = "api/Item/ScraptionItemsForAdmin?lang=en";
  getResource(path, onSuccess, onFail);
}
/* End items */

/* Start orders */
export function getPendingOrdersForAdminApi(onSuccess, onFail) {
  const path = "api/Order/GetScraptionPendingOrdersForAdmin?language=en";
  getResource(path, onSuccess, onFail);
}
export function acceptRejectPendingOrderByAdminApi(data, onSuccess, onFail) {
  const path = "api/Admin/AcceptOrRejectPendingOrderByAdmin?lang=en";
  postResource(path, data, onSuccess, onFail);
}
export function getOrders(onSuccess, onFail) {
  const path = "api/Order/GetOrdersForScraption?lang=en";
  getResource(path, onSuccess, onFail);
}
export function updateOrderStatusApi(data, onSuccess, onFail) {
  const path = "api/Admin/UpdateOrderStatus?lang=en";
  putResource(path, data, onSuccess, onFail);
}
export function getOrderStatusApi(onSuccess, onFail) {
  const path = "api/User/OrderStatus?language=en";
  getResource(path, onSuccess, onFail);
}
/* End orders */

/* Start category */
export function getCategoriesApi(onSuccess, onFail) {
  const path = "api/Category";
  getResource(path, onSuccess, onFail);
}
export function getActiveCategoriesApi(onSuccess, onFail) {
  const path = "api/Category/GetActiveCategories";
  getResource(path, onSuccess, onFail);
}
/* End category */

/* Start store */
export function getStores(onSuccess, onFail) {
  const path = "api/Admin/StoreForAdmin?language=en";
  getResource(path, onSuccess, onFail);
}
export function getActiveStores(onSuccess, onFail) {
  const path = "api/Admin/ActiveStores?language=en";
  getResource(path, onSuccess, onFail);
}
export function addStore(data, onSuccess, onFail) {
  const path = "api/Admin/Store?language=en";
  postResource(path, data, onSuccess, onFail);
}
export function editStore(data, onSuccess, onFail) {
  const path = "api/Admin/Store?language=en";
  putResource(path, data, onSuccess, onFail);
}
export function getAddressFromGeolocation(coords, onSuccess, onFail) {
  const LocatioIQToken = encodeURIComponent("pk.ae679be642804939a90eba2f54ffdb9e")
  const path = `https://us1.locationiq.com/v1/reverse?key=${LocatioIQToken}&lat=${coords.lat}&lon=${coords.lng}&format=json`;
  getResource(path, onSuccess, onFail, false, false);
}
/* End store */

/* Start sales requests */
export function getMerchantSalesRequests(onSuccess, onFail) {
  const path = "api/Dealer/SalesRequestForAdmin?language=en";
  getResource(path, onSuccess, onFail);
}

export function AcceptOrRejectMerchantSaleRequest(data, onSuccess, onFail) {
  const path = "api/Dealer/AnswerDealerSaleRequest?language=en";
  putResource(path, data, onSuccess, onFail);
}
/* End sales requests */

/* Start scrappers sales requests */
export function getScrappersSalesRequests(onSuccess, onFail) {
  const path = "api/Dealer/ScrapperSalesRequestForAdmin?language=en";
  getResource(path, onSuccess, onFail);
}

export function AcceptOrRejectScrappersSaleRequest(data, onSuccess, onFail) {
  const path = "api/Dealer/AnswerScrapperSaleRequest?language=en";
  putResource(path, data, onSuccess, onFail);
}
/* End scrappers sales requests */

/* Start Cheques */
export function getChecks(onSuccess, onFail) {
  const path = "api/Check/All";
  getResource(path, onSuccess, onFail);
}

export function addCheck(data, onSuccess, onFail) {
  const path = "api/Check";
  postResource(path, data, onSuccess, onFail);
}
/* End Cheques */

/* Start Settings */
export function getSettings(onSuccess, onFail) {
  const path = "api/Setting/AllScrapperSettings?language=en";
  getResource(path, onSuccess, onFail);
}

export function updateSettings(data, onSuccess, onFail) {
  const path = "api/Setting/ScrapperSetting?language=en";
  putResource(path, data, onSuccess, onFail);
}
/* End Settings */

/* Start Cans Sales Requests */
export function getCansSalesRequests(data, onSuccess, onFail) {
  const path =
    // "api/Dealer/ScrapperCansHistoryWithMultipleTypesForAdmin?language=en";
    // `api/Dealer/ScrapperCansHistoryWithMultipleTypesForAdminV2?language=en&perPage=10&pageNumber=${params.currentPage}&fullName=${params.fullName}&mobile=${params.mobile}&totalPounds=${params.totalPounds}&totalPoints=${params.totalPoints}&totalQuantity=${params.totalQuantity}&pointStatus=${params.pointStatus}&from=${params.from}&to=${params.to}`;
    `api/Dealer/ScrapperCansHistoryWithMultipleTypesForAdminV2?language=en`;
  postResource(path, data, onSuccess, onFail);
}

export function AcceptOrRejectCansSalesRequest(data, onSuccess, onFail) {
  const path = "api/Dealer/AcceptOrRejectSalesRequestNew?language=en";
  postResource(path, data, onSuccess, onFail);
}

export function getQuantityUnits(onSuccess, onFail) {
  const path = "api/Dealer/UnitTypes?language=en";
  getResource(path, onSuccess, onFail);
}

export function getSupplyingTypesForCansRequest(onSuccess, onFail) {
  const path = "api/Dealer/ScrapperSupplyTypes?language=en";
  getResource(path, onSuccess, onFail);
}

export function addCansSaleRequest(data, onSuccess, onFail) {
  const path = "api/Admin/AddListCansSalesRequestByAdmin?language=en";
  postResource(path, data, onSuccess, onFail);
}

export function getScrapperSaleRequestInfo(data, onSuccess, onFail) {
  const path = `api/Admin/ScrapperSalesRequestInfo?salesRequestId=${data.salesRequestId}&supplyTypeId=${data.supplyTypeId}&AmountInGrams=${data.AmountInGrams}&language=en`;
  getResource(path, onSuccess, onFail);
}

export function editScrapSalesRequest(data, onSuccess, onFail) {
  const path = "api/Admin/EditScrapSalesRequest?language=en";
  putResource(path, data, onSuccess, onFail);
}

export function addSupplyTypesToCansSalesRequest(data, onSuccess, onFail) {
  const path = "api/Admin/AddListCansSalesRequestSupplyTypeByAdmin?language=en";
  postResource(path, data, onSuccess, onFail);
}

export function deleteSupplyTypeFromCansSalesRequest(typeId, onSuccess, onFail) {
  const path = `api/Admin/DeleteScrapSalesRequest?language=en&requestId=${typeId}`;
  deleteResource(path, onSuccess, onFail);
}
/* End Cans Sales Requests */

/* Start Accepted Requests Report */
export function getAcceptedRequestsReport(data, onSuccess, onFail) {
  const path = `api/Admin/GetAcceptedScrapSalesRequestV3?language=en`;
  postResource(path, data, onSuccess, onFail);
}
/* Start Accepted Requests Report */

/* Start Redeemed Points requests */
export function getStoreRedeemPointsRequests(data, onSuccess, onFail) {
  // const path = `api/Dealer/StoreRedeemPointsRequestsV2?language=en&perPage=${params.perPage}&pageNumber=${params.pageNumber}&fullName=${params.fullName}&mobile=${params.mobile}&from=${params.from}&to=${params.to}&pounds=${params.pounds}&points=${params.points}&isDelivered=${params.isDelivered}&address=${params.address}&isExport=${params.isExport || false}`;
  const path = "api/Dealer/StoreRedeemPointsRequestsV3?language=en";
  postResource(path, data, onSuccess, onFail);
}

export function getCashRedeemPointsRequests(onSuccess, onFail) {
  const path = "api/Dealer/CashRedeemPointsRequests?language=en";
  // const path = `api/Dealer/CashRedeemPointsRequestsV2?language=en&perPage=10&pageNumber=${params.currentPage}`;
  getResource(path, onSuccess, onFail);
}

export function MarkStoreRequestAsRedeemed(requestId, onSuccess, onFail) {
  const path = `api/Dealer/SubmitStoreRedeemPoints?redeemId=${requestId}&language=en`;
  postResource(path, {}, onSuccess, onFail);
}

export function MarkCashRequestAsRedeemed(requestId, onSuccess, onFail) {
  const path = `api/Dealer/SubmitCashRedeemPoints?redeemId=${requestId}&language=en`;
  postResource(path, {}, onSuccess, onFail);
}

export function getRedeemOrderStatus(onSuccess, onFail) {
  const path = "api/Admin/GetOrderStatus?language=en";
  getResource(path, onSuccess, onFail);
}

export function changeRedeemOrderStatus(data, onSuccess, onFail) {
  const path = "api/Dealer/ChangeOrderStatus?language=en";
  putResource(path, data, onSuccess, onFail);
}

export function updateRedeemOrderPriceAndAddress(data, onSuccess, onFail) {
  const path = "api/Dealer/AddTotoalPriceAndSavedAddress?language=en";
  putResource(path, data, onSuccess, onFail);
}
/* End Redeemed Points requests */

/* Start Supplying Types */
export function getSupplyingTypes(onSuccess, onFail) {
  const path = "api/ScrapperSupplyType/ForAdmin?language=en";
  getResource(path, onSuccess, onFail);
}
export function addSupplyingType(data, onSuccess, onFail) {
  const path = "api/ScrapperSupplyType?language=en";
  postResource(path, data, onSuccess, onFail);
}
export function addSupplyingTypeWithImage(data, onSuccess, onFail) {
  const path = "api/ScrapperSupplyType/WithImage?language=en";
  postResource(path, data, onSuccess, onFail);
}
export function editSupplyingType(data, onSuccess, onFail) {
  const path = "api/ScrapperSupplyType?language=en";
  putResource(path, data, onSuccess, onFail);
}
export function editSupplyingTypeWithImage(data, onSuccess, onFail) {
  const path = "api/ScrapperSupplyType/WithImage?language=en";
  putResource(path, data, onSuccess, onFail);
}
export function deleteSupplyingType(id, onSuccess, onFail) {
  const path = `api/ScrapperSupplyType?id=${id}&language=en`;
  deleteResource(path, onSuccess, onFail);
}
/* End Supplying Types */

/* Start Marketplace Banner */
export function getMarketplaceBanners(onSuccess, onFail) {
  const path = "api/Banner/ForAdmin?language=en";
  getResource(path, onSuccess, onFail);
}
export function addMarketplaceBanner(data, onSuccess, onFail) {
  const path = "api/Banner?language=en";
  postResource(path, data, onSuccess, onFail);
}
export function editMarketplaceBanner(data, onSuccess, onFail) {
  const path = "api/Banner?language=en";
  putResource(path, data, onSuccess, onFail);
}
export function deleteMarketplaceBanner(id, onSuccess, onFail) {
  const path = `api/Banner?id=${id}&language=en`;
  deleteResource(path, onSuccess, onFail);
}
/* End Marketplace Banner */

/* Start Soical Orders */
export function getSocialOrders(data, onSuccess, onFail) {
  // const path = "api/Dealer/GetSocialOrder?language=en";
  // const path = `api/Dealer/GetSocialOrderV2?language=en`
  const path = `api/Dealer/GetSocialOrderV3?language=en`
  postResource(path, data, onSuccess, onFail);
}
export function getSocialOrderRequestDetailsById(id, onSuccess, onFail) {
  const path = `api/Dealer/GetSocialOrderDetailsByRequestId?RequestId=${id}&language=en`
  getResource(path, onSuccess, onFail);
}
export function getSocialOrderRequestImagesById(id, onSuccess, onFail) {
  const path = `api/Dealer/GetSocialOrderImagesByRequestId?RequestId=${id}&language=en`
  getResource(path, onSuccess, onFail);
}
export function getSocialOrderAddressDetailsById(id, onSuccess, onFail) {
  const path = `api/Dealer/GetAddressDetailsByRequestId?RequestId=${id}&language=en`
  getResource(path, onSuccess, onFail);
}
export function getStoreItemActiveCategories(onSuccess, onFail) {
  const path = "api/Category/StoreItemActiveCategoriesForList?language=en";
  getResource(path, onSuccess, onFail);
}
export function assignOrdersToCourier(data, onSuccess, onFail) {
  const path = "api/Courier/AssignCourier?language=en";
  postResource(path, data, onSuccess, onFail);
}
export function editSocialOrderAddress(data, onSuccess, onFail) {
  const path = "api/UserAddress/AddressInfoByAdmin?language=en";
  putResource(path, data, onSuccess, onFail);
}
export function getUserRequestStatus(onSuccess, onFail) {
  const path = "api/Dealer/UserRequestStatus?language=en";
  getResource(path, onSuccess, onFail);
}
export function updateUserRequestStatus(data, onSuccess, onFail) {
  const path = "api/Dealer/SocialUserRequestStatus?language=en";
  putResource(path, data, onSuccess, onFail);
}
// export function getAssignmentStatus(onSuccess, onFail) {
//   const path = "/api/Dealer/AsigningStatus";
//   getResource(path, onSuccess, onFail);
// }
export function updateAssignmentRequestStatus(data, onSuccess, onFail) {
  const path = "api/Dealer/AsigningStatus";
  putResource(path, data, onSuccess, onFail);
}
export function getLogisticTeams(onSuccess, onFail) {
  const path = "api/Dealer/GetLogisticTeam?language=en";
  getResource(path, onSuccess, onFail);
}
export function assignLogisticAdmin(data, onSuccess, onFail) {
  const path = "api/Dealer/AssignLogisticTeam?language=en";
  putResource(path, data, onSuccess, onFail);
}
export function RateOrder(data, onSuccess, onFail) {
  const path = "api/Dealer/EditRating?language=en";
  putResource(path, data, onSuccess, onFail);
}
export function bulkAssignUserStatus(data, onSuccess, onFail) {
  const path = "api/Dealer/ListSocialUserRequestStatus?language=en";
  putResource(path, data, onSuccess, onFail);
}
export function getRequestAssignmentLogs(id, onSuccess, onFail) {
  const path = `api/Courier/RequestCourierAssigningLogs?requestId=${id}&language=en`
  getResource(path, onSuccess, onFail);
}
export function updateCallCenterStatus(data, onSuccess, onFail) {
  const path = "api/Dealer/CallCenterStatus?language=en";
  putResource(path, data, onSuccess, onFail);
}
export function assignOrdersToDistrict(data, onSuccess, onFail) {
  const path = "api/Courier/AssignDistrict?language=en";
  postResource(path, data, onSuccess, onFail);
}
/* End Soical Orders */

/* Start Courier */
export function getCourier(onSuccess, onFail) {
  const path = "api/Courier?language=en";
  getResource(path, onSuccess, onFail);
}
export function getMonthlyCourierOrders(id, onSuccess, onFail) {
  const path = `api/Courier/MonthlyCourierOrdersReport?language=en&courierId=${id}`;
  getResource(path, onSuccess, onFail);
}
export function getActiveCouriers(onSuccess, onFail) {
  const path = "api/Courier/CouriersForList?language=en";
  getResource(path, onSuccess, onFail);
}
export function addCourier(onSuccess, onFail) {
  const path = "api/Courier?language=en";
  postResource(path, onSuccess, onFail);
}
export function editCourier(onSuccess, onFail) {
  const path = "api/Courier?language=en";
  putResource(path, onSuccess, onFail);
}
/* End Courier */

/* Start Courier Report */
export function getCourierReport(data, onSuccess, onFail) {
  // const path = "api/Admin/CourierReport?language=en";
  const path = "api/Admin/CourierReportV2?language=en";
  postResource(path, data, onSuccess, onFail);
}
export function getCouriersWhoHaveDailyOrders(data, onSuccess, onFail) {
  const path = `api/Dealer/GetTodayCourierOrders?language=en&from=${data.from}&to=${data.to}`;
  getResource(path, onSuccess, onFail);
}
export function getDailyOrdersForCourier(data, onSuccess, onFail) {
  const path = "api/Dealer/GetTodayCourierOrderDetails?language=en";
  postResource(path, data, onSuccess, onFail);
}
/* End Courier Report */

/* Start Government */
export function getAllGovernmentsForAdmin(onSuccess, onFail) {
  const path = "api/District/GovernmentsForAdmin";
  getResource(path, onSuccess, onFail);
}
export function getGovernmentsForList(onSuccess, onFail) {
  const path = "api/District/GovernmentsForList?language=en";
  getResource(path, onSuccess, onFail);
}
export function addGovernment(data, onSuccess, onFail) {
  const path = "api/District/Goverment";
  postResource(path, data, onSuccess, onFail);
}
export function editGovernment(data, onSuccess, onFail) {
  const path = "api/District/Government";
  putResource(path, data, onSuccess, onFail);
}
/* End Government */

/* Start City */
export function getAllCitiesForAdmin(onSuccess, onFail) {
  const path = "api/District/AllDistrictForAdmin";
  getResource(path, onSuccess, onFail);
}
export function getCitiesForList(govenmentId, onSuccess, onFail) {
  const path = `api/District/DistrictsForList?govenmentId=${govenmentId}&language=en`;
  getResource(path, onSuccess, onFail);
}
export function addCity(data, onSuccess, onFail) {
  const path = "api/District";
  postResource(path, data, onSuccess, onFail);
}
export function editCity(data, onSuccess, onFail) {
  const path = "api/District";
  putResource(path, data, onSuccess, onFail);
}
/* End City */

/* Start Collection types */
export function getCollectionTypes(onSuccess, onFail) {
  const path = "api/Dealer/getCollectionTypesForAdmin";
  getResource(path, onSuccess, onFail);
}
/* End Collection types */

/* Start refund Apis */
export function refundRedeemedOrder(data, onSuccess, onFail) {
  const path = `api/Dealer/RefundOrder?language=en`;
  postResource(path, data, onSuccess, onFail);
}
export function refundRedeemedProduct(data, onSuccess, onFail) {
  const path = `api/Dealer/RefundItem?language=en`;
  postResource(path, data, onSuccess, onFail);
}
export function refundRedeemedPoints(requestId, onSuccess, onFail) {
  const path = `api/Dealer/RefundPoints?requestIdentifierId=${requestId}&language=en`;
  postResource(path, {}, onSuccess, onFail);
}
/* End refund Apis */

/* Start store items */
export function getStoreItems(onSuccess, onFail) {
  const path = "api/GMStore/StoreItemsForAdmin?language=en";
  getResource(path, onSuccess, onFail);
}
export function uploadStoreItemImage(data, onSuccess, onFail) {
  const path = "api/GMStore/AddItemImage?language=en";
  postResource(path, data, onSuccess, onFail);
}
export function deleteItemImage(data, onSuccess, onFail) {
  const path = `api/GMStore/DeleteItemImage?language=en&imagePath=${data.imagePath}&itemId=${data.itemId}`;
  postResource(path, {}, onSuccess, onFail);
}
export function addStoreItem(data, onSuccess, onFail) {
  const path = "api/GMStore/AddStoreItemV2?language=en";
  postResource(path, data, onSuccess, onFail);
}
export function editStoreItem(data, onSuccess, onFail) {
  const path = "api/GMStore/EditStoreItemV2?language=en";
  putResource(path, data, onSuccess, onFail);
}
export const deleteStoreItem = (itemId, onSuccess, onFail) => {
  const path = `api/GMStore/StoreItem?language=en&itemId=${itemId}`;
  deleteResource(path, onSuccess, onFail);
};
export function getStoreOrders(onSuccess, onFail) {
  const path = "api/GMStore/StoreOrders?language=en";
  getResource(path, onSuccess, onFail);
}
export function submitStoreExchangeDelivery(orderId, onSuccess, onFail) {
  const path = `api/GMStore/SubmitStoreExchangeDelivery?storeExchangeId=${orderId}&language=en`;
  postResource(path, {}, onSuccess, onFail);
}
export function changeStatusOfStoreItemsList(data, onSuccess, onFail) {
  const path = `api/GMStore/ActivateOrDeatcivateStoreItemList?language=en`;
  putResource(path, data, onSuccess, onFail);
}
/* End store items */

/* Start store categories */
export function getStoreCategories(onSuccess, onFail) {
  const path = "api/Category/StoreItemcategoriesForAdmin?language=en";
  getResource(path, onSuccess, onFail);
}
export function getActiveStoreCategories(onSuccess, onFail) {
  const path = "api/Category/StoreItemActiveCategoriesForList?language=en";
  getResource(path, onSuccess, onFail);
}
export function addStoreCategory(data, onSuccess, onFail) {
  const path = "api/Category/AddStoreItemCategory?language=en";
  postResource(path, data, onSuccess, onFail);
}
export function editStoreCategory(data, onSuccess, onFail) {
  const path = "api/Category/EditStoreItemCategory?language=en";
  putResource(path, data, onSuccess, onFail);
}
export const deleteStoreCategory = (categoryId, onSuccess, onFail) => {
  const path = `api/Category/DeleteStoreItemCategory?language=en&id=${categoryId}`;
  deleteResource(path, onSuccess, onFail);
};
/* End store categories */

/* Start store subcategories */
export function getStoreSubcategories(onSuccess, onFail) {
  const path = "api/SubCategory/StoreItemSubCategoriesForAdmin?language=en";
  getResource(path, onSuccess, onFail);
}
export function getActiveStoreSubcategories(categoryId, onSuccess, onFail) {
  const path = `api/SubCategory/GetActiveSubCategoryByCategoryId?language=en&CategoryId=${categoryId}`;
  getResource(path, onSuccess, onFail);
}
export function addStoreSubcategory(data, onSuccess, onFail) {
  const path = "api/SubCategory/AddStoreItemSubCategory?language=en";
  postResource(path, data, onSuccess, onFail);
}
export function editStoreSubcategory(data, onSuccess, onFail) {
  const path = "api/SubCategory/EditStoreItemSubCategory?language=en";
  putResource(path, data, onSuccess, onFail);
}
export function deleteStoreSubcategory(subcategoryId, onSuccess, onFail) {
  const path = `api/SubCategory/DeleteStoreItemSubCategory?language=en&id=${subcategoryId}`;
  deleteResource(path, onSuccess, onFail);
}
export function makeCategoryAsSubCategoryOfAnotherCategory(data, onSuccess, onFail) {
  const path = "api/Category/MaleCategoryAsSubCategoryOfAnotherCategory?language=en";
  postResource(path, data, onSuccess, onFail);
}
/* End store subcategories */

/* Start Do don't images */
export function getDoDontImages(onSuccess, onFail) {
  const path = "api/Cart/GetSupplyCartExampleForAdmin";
  getResource(path, onSuccess, onFail);
}
export function addDoDontImage(data, onSuccess, onFail) {
  const path = "api/Cart/AddSupplyCartExample";
  postResource(path, data, onSuccess, onFail);
}
export function updateDoDontImage(data, onSuccess, onFail) {
  const path = "api/Cart/EditSupplyCartExample";
  putResource(path, data, onSuccess, onFail);
}
export function deleteDoDontImage(id, onSuccess, onFail) {
  const path = `api/Cart/DeleteSupplyCartExample?id=${id}`;
  deleteResource(path, onSuccess, onFail);
}
/* End Do don't images */

/* Start Home banners */
export function getHomeBanners(onSuccess, onFail) {
  const path = "api/Banner/HomeBannerForAdmin";
  getResource(path, onSuccess, onFail);
}
export function addHomeBanner(data, onSuccess, onFail) {
  const path = "api/Banner/AddHomeBanner";
  postResource(path, data, onSuccess, onFail);
}
export function updateHomeBanner(data, onSuccess, onFail) {
  const path = "api/Banner/EditHomeBanner";
  putResource(path, data, onSuccess, onFail);
}
export function deleteHomeBanner(id, onSuccess, onFail) {
  const path = `api/Banner/DeleteHomeBanner?id=${id}`;
  deleteResource(path, onSuccess, onFail);
}
/* End Home banners */

/* Start Notification */
export function sendNotification(data, onSuccess, onFail) {
  const path = "api/Notification/SendNotificationToAllScrappers";
  postResource(path, data, onSuccess, onFail);
}
/* End Notification */

/* Start Partners */
export function getPartners(data, onSuccess, onFail) {
  const path = "api/Partner/GetPartnersForAdminV2?language=en";
  postResource(path, data, onSuccess, onFail);
}

export function getAllPartnersWithoutPagination(onSuccess, onFail) {
  const path = "api/Partner/GetPartnersForAdmin?language=en";
  getResource(path, onSuccess, onFail);
}

export function addPartner(data, onSuccess, onFail) {
  const path = "api/Partner?language=en";
  postResource(path, data, onSuccess, onFail);
}

export function editPartner(data, onSuccess, onFail) {
  const path = "api/Partner/EditStoreItemCategory?language=en";
  putResource(path, data, onSuccess, onFail);
}

export function getPartnersOfCategoryForList(categoryId, onSuccess, onFail) {
  const path = `api/Partner/PartnerCategoryForList?language=en&categoryId=${categoryId}`;
  getResource(path, onSuccess, onFail);
}

export function getPartnersOfSubcategoryForList(subcategoryId, onSuccess, onFail) {
  const path = `api/Partner/PartnerSubCategoryForList?language=en&subcategoryId=${subcategoryId}`;
  getResource(path, onSuccess, onFail);
}

export function getPartnersForList(onSuccess, onFail) {
  const path = "api/Partner/PartnerForList?language=en";
  getResource(path, onSuccess, onFail);
}
/* End Partners */

/* Start Partnership */
export function getPartnership(onSuccess, onFail) {
  const path = "api/PartnerShip/ForAdmin?language=en";
  getResource(path, onSuccess, onFail);
}

export function addPartnership(data, onSuccess, onFail) {
  const path = "api/PartnerShip?language=en";
  postResource(path, data, onSuccess, onFail);
}

export function editPartnership(data, onSuccess, onFail) {
  const path = "api/PartnerShip?language=en";
  putResource(path, data, onSuccess, onFail);
}

export function sendCourierToPartnership(data, onSuccess, onFail) {
  const path = "api/PartnerShip/AssignCourierToPartnerShip?language=en";
  postResource(path, data, onSuccess, onFail);
}
/* End Partnership */

/* Start Sales Requests Returns */
export function getMerchants(onSuccess, onFail) {
  const path = "api/Dealer/GetMerchants?language=en";
  getResource(path, onSuccess, onFail);
}

export function getSalesRequestsReturns(onSuccess, onFail) {
  const path = "api/Dealer/GetSalesRequestReturns?language=en";
  getResource(path, onSuccess, onFail);
}

export function addSalesRequestsReturn(data, onSuccess, onFail) {
  const path = "api/Dealer/SaleRequestReturns?language=en";
  postResource(path, data, onSuccess, onFail);
}

export function getCategoriesForList(onSuccess, onFail) {
  const path = "api/ScrapperSupplyType/GetSupplyTypesForList?language=en";
  getResource(path, onSuccess, onFail);
}

export function deleteSalesRequestsReturn(requestId, onSuccess, onFail) {
  const path = `api/Dealer/SaleRequestReturns?id=${requestId}&language=en`;
  deleteResource(path, onSuccess, onFail);
}
/* End Sales Requests Returns */

/* Start Merchant Periods */
export function getMerchantPeriods(onSuccess, onFail) {
  const path = "api/ScrapperSupplyType/GetMerchantReceivingPeriodForAdmin?language=en";
  getResource(path, onSuccess, onFail);
}

export function getMerchantPeriodsForList(onSuccess, onFail) {
  const path = "api/ScrapperSupplyType/GetMerchantReceivingPeriodForList?language=en";
  getResource(path, onSuccess, onFail);
}

export function addMerchantPeriods(data, onSuccess, onFail) {
  const path = "api/ScrapperSupplyType/AddMerchantReceivingPeriod?language=en";
  postResource(path, data, onSuccess, onFail);
}

export function editMerchantPeriods(data, onSuccess, onFail) {
  const path = "api/ScrapperSupplyType/EditMerchantReceivingPeriod?language=en";
  putResource(path, data, onSuccess, onFail);
}
/* End Merchant Periods */

/* Start Merchant Scrap Types */
export function getMerchantScrapTypes(onSuccess, onFail) {
  const path = "api/ScrapperSupplyType/GetScrraperSuppliesTypeMerchantPricesForAdmin?language=en";
  getResource(path, onSuccess, onFail);
}

export function addMerchantScrapTypesPriceList(data, onSuccess, onFail) {
  const path = "api/ScrapperSupplyType/AddMerchantScrapperTypePrices?language=en";
  postResource(path, data, onSuccess, onFail);
}
/* End Merchant Scrap Types */

/* Start Districts */
export function getCourierDistricts(onSuccess, onFail) {
  const path = "api/District/CourierDistrictForAdmin?language=en";
  getResource(path, onSuccess, onFail);
}

export function getCourierDistrictsForList(govenmentId, onSuccess, onFail) {
  const path = `api/District/CourierDistrictsForList?govenmentId=${govenmentId}&language=en`;
  getResource(path, onSuccess, onFail);
}

export function addCourierDistrict(data, onSuccess, onFail) {
  const path = "api/District/CourierDistrict?language=en";
  postResource(path, data, onSuccess, onFail);
}

export function editCourierDistrict(data, onSuccess, onFail) {
  const path = "api/District/CourierDistrict?language=en";
  putResource(path, data, onSuccess, onFail);
}

export function deleteCourierDistrict(id, onSuccess, onFail) {
  const path = `api/District/CourierDistrict?language=en&id=${id}`;
  deleteResource(path, onSuccess, onFail);
}

export function assignCourierToDistrict(data, onSuccess, onFail) {
  const path = "api/Courier/AssignCourierDistrict?language=en";
  postResource(path, data, onSuccess, onFail);
}
/* End Districts */
