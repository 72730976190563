import { useEffect, useRef } from "react";
import { Button, Form, Input } from "antd";
import { theFirstEncounteredLetterIsArabic } from "helpers/RegexPatterns";
import { CloseCircleFilled } from "@ant-design/icons";
import { handleAllowNumbersOnly } from "helpers/helpfulFunctions";

const RoomsSearch = ({
    onSearch,
    placeholder,
    focusCursor,
    form,
    searchValue,
    messagesSearch
}) => {
    const inputRef = useRef();
    const initialValues = {
        search: "",
    }

    const validateInput = (_, value) => {
        if (value && value.trim().length !== 0) {
            onSearch(value)
        } else {
            onSearch("")
        }
        return Promise.resolve();
    };

    useEffect(() => {
        if (focusCursor && inputRef?.current) {
            inputRef.current.focus();
        }
    }, [focusCursor, inputRef])

    return (
        <Form
            form={form}
            onFinish={() => false}
            initialValues={initialValues}
            className="chat__rooms__search-holder"
        >
            <div className="position-relative">
                <Form.Item
                    name="search"
                    rules={[{ validator: validateInput }]}
                    className="d-block mb-0"
                >
                    <Input
                        className="d-block w-100 fw-bold fsize-11"
                        placeholder={placeholder}
                        onKeyDown={messagesSearch ? () => { } : handleAllowNumbersOnly}
                        ref={inputRef}
                        dir={
                            theFirstEncounteredLetterIsArabic.test(searchValue)
                                ? "rtl"
                                : "ltr"
                        }
                    />
                </Form.Item>
                {searchValue ? (
                    <Button
                        type="button"
                        onClick={() => {
                            form.resetFields()
                            onSearch("")
                        }}
                        className="chat__rooms__search-holder__clear-btn position-absolute top-50 translate-middle-y lh-1 h-auto d-block p-0 border-0 outline-none shadow-none bg-transparent"
                    >
                        <CloseCircleFilled />
                    </Button>
                ) : (
                    ""
                )}
            </div>
        </Form>
    );
}

export default RoomsSearch;