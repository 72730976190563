import { useEffect, useState } from 'react';

// components
import MainHeader from "../SubComponents/MainHeader";
import ChatsAside from './ChatsAside';
import ChatHolder from './ChatHolder';

const Chat = ({ scoutsChat }) => {
    const [unsubscribeActiveChat, setUnsubscribeActiveChat] = useState(false)
    const [activeRoom, setActiveRoom] = useState("");

    useEffect(() => {
        setActiveRoom("");
    }, [scoutsChat])

    return (
        <section key={scoutsChat} className="users chat">
            <div className="users__all-contents">
                <div className="users__content">
                    <div className="users__usersData d-flex flex-column">
                        <div className="pb-3 mb-3">
                            <MainHeader title={scoutsChat ? "Green Scouts Chat" : "Big Scrappers Chat"} />
                        </div>
                        <div className="row m-0 flex-md-fill pb-3">
                            <div className="col-md-4 px-0 mb-3 mb-md-0">
                                <ChatsAside
                                    activeRoom={activeRoom}
                                    onUnsubscribeActiveChat={
                                        (value) => setUnsubscribeActiveChat(value)
                                    }
                                    onActiveChange={(id) => setActiveRoom(id)}
                                    scoutsChat={scoutsChat}
                                />
                            </div>
                            {activeRoom ? (
                                <div className="col-md-8 px-0 ps-md-3">
                                    <ChatHolder
                                        unSubscribe={unsubscribeActiveChat}
                                        room={activeRoom}
                                        scoutsChat={scoutsChat}
                                    />
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Chat;