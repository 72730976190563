import { useEffect, useRef, useState } from 'react';
import { Form } from 'antd';
import { doc, setDoc } from 'firebase/firestore';
import { useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { getCollectionName, listenToUserChat } from 'apis/firebaseService';
import db from 'apis/firebase';

// components
import ChatHeader from './ChatHeader';
import ChatFooter from './ChatFooter';
import ChatBody from './ChatBody';
import NewMessagesAlertBtn from './NewMessagesAlertBtn';

const ChatHolder = ({ room, unSubscribe, scoutsChat }) => {
    const [key, setKey] = useState(1);
    const [data, setData] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);
    const [searchResults, setSearchResults] = useState(null);
    const [previousRoom, setPreviousRoom] = useState(undefined);
    const [searchValue, setSearchValue] = useState("");
    const [unseenMsg, setUnseenMsg] = useState(false);
    const { authorization } = useSelector((state) => state.auth);
    const scrollParent = useRef(null);
    const [form] = Form.useForm();
    const unSubscribeRef = useRef();

    const handleScrollOnParent = (e) => {
        if (e.target.scrollTop >= -54 && unseenMsg) {
            setUnseenMsg(false)
        }
    }

    useEffect(() => {
        if (unSubscribe) {
            unSubscribeRef?.current();
            return
        }
        if (room) {
            if (previousRoom !== room) {
                setKey(key + 1)
            }
            setPreviousRoom(room);

            unSubscribeRef.current = listenToUserChat(
                room,
                (roomData) => {
                    setData(roomData)
                    setDataLoading(false)
                    // reset messages counter
                    const collectionName = getCollectionName(scoutsChat);
                    setDoc(doc(db, collectionName, room), {
                        unReadMessagesCountFromUser: 0,
                    }, { merge: true });
                },
                scoutsChat
            )
            return () => {
                unSubscribeRef.current()
            }
        }
    }, [room, unSubscribe, scoutsChat])

    useEffect(() => {
        setUnseenMsg(false)
        setData(null)
        setDataLoading(true)
        setSearchValue("")
        setSearchResults(null)
    }, [room])

    useEffect(() => {
        if (data && scrollParent) {
            const lastDayMessagesObj = data.messages[data.messages.length - 1];
            const lastMessage = lastDayMessagesObj.messages[lastDayMessagesObj.messages.length - 1]
            const isLastMessageSentByCurrentAdmin = lastMessage.adminId === authorization.id;
            if (scrollParent.current.scrollTop < -77 && !isLastMessageSentByCurrentAdmin) {
                setUnseenMsg(true)
            } else {
                setUnseenMsg(false)
                scrollParent.current.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth"
                })
            }
        }
    }, [data])

    useEffect(() => {
        if (data) {
            if (searchValue) {
                const regex = new RegExp(searchValue, "i")
                const results = []
                data.messages.forEach((dayMsgs) => {
                    const messages = dayMsgs.messages;
                    messages.forEach((msg) => {
                        if (
                            (msg.type === 1 && regex.test(msg.message)) ||
                            (msg.type === 4 && regex.test(msg.mediaName))
                        ) {
                            const resultsDayIndx = results.findIndex((day) => (
                                day.date === dayMsgs.date
                            ))
                            if (resultsDayIndx > -1) {
                                results[resultsDayIndx].messages.push(msg)
                            } else {
                                const newDayObj = { date: dayMsgs.date, messages: [] };
                                newDayObj.messages.push(msg)
                                results.push(newDayObj)
                            }
                        }
                    })
                })
                setSearchResults(() => ({ ...data, messages: results }))
            } else {
                setSearchResults(null)
            }
            setDataLoading(false)
        }
    }, [data, searchValue])

    return (
        <div key={key} className="chat__chat-holder position-relative d-flex flex-column">
            <ChatHeader
                data={data}
                form={form}
                searchValue={searchValue}
                onSearch={(value) => {
                    if (value !== searchValue) {
                        setDataLoading(true)
                    }
                    setSearchValue(() => value)
                }}
            />
            <div className="position-relative overflow-hidden flex-fill d-flex flex-column">
                {unseenMsg ? (
                    <NewMessagesAlertBtn onClick={() => {
                        scrollParent.current.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth"
                        })
                    }} />
                ) : (
                    ""
                )}
                <div
                    onScroll={handleScrollOnParent}
                    ref={scrollParent}
                    className="chat__chat-holder__body d-flex flex-column-reverse flex-fill position-relative"
                >
                    {!dataLoading ? (
                        <ChatBody
                            data={searchResults ? searchResults : data}
                            searchValue={searchValue}
                        />
                    ) : (
                        <div className='h-100 d-flex justify-content-center align-items-center'>
                            <LoadingOutlined />
                        </div>
                    )}
                </div>
                {!searchValue ? (
                    <ChatFooter room={room} scoutsChat={scoutsChat}/>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
}

export default ChatHolder;