const ROUTES = {
  SIGNIN: "/",
  LOGOUT: "logout",
  NOT_FOUND: "*",
  HOME: "home",
  DASHBOARD: "dashboard",
  CATEGORIES: "types",
  ADMINS: "admins",
  STORES: "stores",
  SETTINGS: "settings",
  SCRAP_SALES_REQUESTS: "scrap-sales-requests",
  REDEEM_POINTS_REQUESTS: "redeem-points-requests",
  SUPPLYING_TYPES: "supplying-types",
  NOT_HAVE_ACCESS: "not-have-access",
  DEALERS: "scrappers",
  ACCEPTED_REQUESTS_REPORT: "accepted-requests-report",
  HOME_BANNER: "home-banner",
  MARKETPLACE_BANNER: "marketplace-banner",
  COURIER: "couriers",
  COURIER_REPORT: "courier-report",
  SOCIAL_ORDERS: "social-orders",
  GOVERNMENT: "governments",
  CTIY: "areas",
  DAILY_COURIES: "daily-couriers",
  COMPLETED_ORDERS: "completed-orders",
  DO_DONT_IMAGES: "do-dont-images",
  DEFINE_AREA: "define-area",
  STORE_PRODUCTS: "store-products",
  STORE_CATEGORIES: "store-categories",
  STORE_SUBCATEGORIES: "store-subcategories",
  NOTIFICATION: "notification",
  PARTNERS: "partners",
  PARTNERSHIP: "partnership",
  CHAT_SCOUTS: "scouts-chat",
  CHAT_SCRAPPERS: "scrappers-chat",
  MERCHANT_SALES_REQUESTS: "merchant-sales-requests",
  SALES_REQUESTS_RETURNS: "sales-requests-returns",
  MERCHANT_PERIODS: "merchant-periods",
  MERCHANT_SCRAP_TYPES: "merchant-scrap-types",
  COURIER_DISTRICTS: "courier-districts",
  // Orders: "/orders",
  // PENDINGORDERS: "/pending-orders",
  // ITEMS: "/items",
  // DEALERSCHECKS: "/scrappers-checks",
  // SALESREQUESTS: "/scrappers-sales-requests",
  // SCRAPPERSSALESREQUESTS: "/robabekia-sales-requests",
};

export default ROUTES;
