const CourierDistrictsIcon = () => {
    return (
        <svg fill="#000000" width="24" height="24" version="1.1" id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 444.702 444.702">
            <g>
                <path d="M440.584,422.064l-17.648-39.943c-2.211-5.005-4.339-13.846-4.646-19.306l-1.405-24.876
		c-0.339-5.999-0.616-127.558-0.616-127.558c0.003-8.706-5.506-16.136-15.768-16.475l-43.597-1.899
		c-5.57-0.245-14.642,2.113-16.917,13.664l-7.925,50.38c-0.479,3.047-3.605,5.762-6.689,5.809l-34.466,0.528
		c-8.158,0.125-14.965,5.502-15.496,12.239c-0.235,2.971,0.793,5.907,2.894,8.267c2.666,2.994,6.788,4.807,11.309,4.974l50.04,1.852
		l-0.126,11.284l-22.076,57.663c-2.34,6.108-4.68,15.997-5.326,22.514l-4.665,47.037c-0.43,4.332,0.894,8.446,3.728,11.586
		c2.834,3.14,6.792,4.877,11.145,4.892l1.968,0.007c0.001,0,0.001,0,0.002,0c8.509,0,16.208-6.877,17.163-15.332l4.367-38.684
		c0.587-5.195,3.611-13.589,6.471-17.962l22.698-34.71l9.337,38.895c1.487,6.194,4.98,15.887,7.786,21.607l16.46,33.55
		c3.043,6.203,9.913,10.372,17.096,10.372c1.408,0,2.794-0.167,4.117-0.495l1.188-0.295c4.235-1.051,7.705-3.777,9.52-7.479
		S442.347,426.056,440.584,422.064z M324.299,439.7L324.299,439.7v0.002V439.7z"/>
                <path d="M308.769,251.373L308.769,251.373c8.078,0,15.104-6.554,15.666-14.611l3.163-45.467c0.281-4.05-1.06-7.888-3.776-10.805
		s-6.448-4.528-10.509-4.536l-62.505-0.109c-8.077,0-15.104,6.555-15.665,14.611l-3.164,45.467c-0.281,4.05,1.06,7.887,3.776,10.805
		c2.717,2.918,6.449,4.529,10.51,4.536l62.479,0.109H308.769z M308.769,246.372L308.769,246.372L308.769,246.372L308.769,246.372z"
                />
                <path d="M327.16,130.494c1.979,5.402,7.782,9.962,13.498,10.606l9.21,1.039c-0.501,6.501,0.695,13.05,3.578,19.093
		c4.342,9.102,11.969,15.967,21.474,19.331c4.071,1.442,8.31,2.173,12.597,2.173c0.002,0,0.001,0,0.003,0
		c15.955,0,30.256-10.112,35.584-25.161c3.366-9.504,2.828-19.75-1.514-28.852c-4.342-9.101-11.968-15.967-21.474-19.333
		c-4.072-1.44-8.31-2.171-12.595-2.171c-7.386,0-14.415,2.169-20.366,5.987l-33.133-3.152c-0.397-0.038-0.785-0.056-1.159-0.056
		c-3.237,0-6.052,1.371-7.722,3.762c-1.713,2.452-2.022,5.696-0.849,8.901L327.16,130.494z"/>
                <path d="M182.103,0H13.817c-6.065,0-11,4.935-11,11v281.284c0,6.065,4.935,11,11,11h168.286c6.065,0,11-4.935,11-11V11
		C193.103,4.935,188.169,0,182.103,0z M61.337,201.327H26.671v-16.756h34.667V201.327z M162.228,138.239H33.693V32.032h128.535
		V138.239z"/>
            </g>
        </svg>
    );
}

export default CourierDistrictsIcon;