import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { updateChatNotifications } from "apis/firebaseService";
import { handleLogout } from './../../helpers/helpfulFunctions';
import { updateCurrentPath } from "../../store/dynamicData";
import ROUTES from "constants/NewRoutes";

// assets
import logoutIcon from "../../assets/icons/sidebar/logout.svg";
import logo from "../../assets/images/new-logo.png";

// Icons components
import AdminsIcon from "./Icons/AdminsIcon";
import TypesIcon from "./Icons/TypesIcon";
import StoresIcon from "./Icons/StoresIcon";
import SettingsIcon from "./Icons/SettingsIcon";
import RedeemPointsIcon from "./Icons/RedeemPointsIcon";
import CansSalesRequestsIcon from "./Icons/CansSalesRequestsIcon";
import SupplyingTypesIcon from "./Icons/SupplyingTypesIcon";
import DealersIcon from "./Icons/DealersIcon";
import AcceptedReportIcon from "./Icons/AcceptedReportIcon";
import HomeBannerIcon from "./Icons/HomeBannerIcon";
import SocialOrdersIcon from "./Icons/SocialOrdersIcon";
import CourierIcon from "./Icons/CourierIcon";
import CourierReportIcon from "./Icons/CourierReportIcon";
import GovernmentIcon from "./Icons/GovernmentIcon";
import CityIcon from "./Icons/CityIcon";
import DoDontImageIcon from "./Icons/DoDontImageIcon";
import SalesRequestsIcon from "./Icons/SalesRequestsIcon";
import DealersChecksIcon from "./Icons/DealersChecksIcon";
import ScrappersSalesRequestsIcon from "./Icons/ScrappersSalesRequestsIcon";
import ItemsIcon from "./Icons/ItemsIcon";
import AllOrdersIcon from "./Icons/AllOrdersIcon";
import PendingOrdersIcon from "./Icons/PendingOrdersIcon";
import StoreCategoriesIcon from "./Icons/StoreCategoriesIcon";
import StoreSubcategoriesIcon from "./Icons/StoreSubcategoriesIcon";
import StoreItemsIcon from "./Icons/StoreItemsIcon";
import NotificationIcon from "./Icons/NotificationIcon";
import PartnersIcon from "./Icons/PartnersIcon";
import DailyCouriersIcon from "./Icons/DailyCouriersIcon";
import MarketplaceBannerIcon from "./Icons/MarkeplaceBannerIcon";
import PartnershipIcon from "./Icons/PartnershipIcon";
import ChatIcon from "./Icons/ChatIcon";
// import DefineAreaIcon from "./Icons/DefineAreaIcon";
import SalesRequestsReturnsIcon from './Icons/SalesRequestsReturnsIcon';
import MerchantSalesRequestsIcon from "./Icons/MerchantSalesRequestsIcon";
import MerchantPeriodsIcon from "./Icons/MerchantPeriodsIcon";
import MerchantScrapTypesIcon from "./Icons/MerchantScrapTypesIcon";
import ScrappersChatIcon from "./Icons/ScrappersChatIcon";
import CourierDistrictsIcon from "./Icons/CourierDistrictsIcon";

function SideBar() {
  const [activeTab, setActiveTab] = useState("");
  const { authorization } = useSelector((state) => state?.auth);
  const {
    notifications: chatNotifications,
    greenScoutsNotifications
  } = useSelector((state) => state.chat);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const adminRoles = authorization.roles;
  const environment = process.env.REACT_APP_NODE_ENV;

  // environment: [1, 2] -->  1 = test and 2 = live
  const categoriesData = [
    {
      id: 0,
      icon: <AdminsIcon />,
      name: "Admins",
      link: ROUTES.ADMINS,
      roles: [1, 9],
      environment: [1, 2],
    },
    {
      id: 1,
      icon: <DealersIcon />,
      name: "Scrappers",
      link: ROUTES.DEALERS,
      roles: [1, 9, 11],
      environment: [1, 2],
    },
    {
      id: 2,
      icon: <CansSalesRequestsIcon />,
      name: "Scrap Sales Requests",
      link: ROUTES.SCRAP_SALES_REQUESTS,
      roles: [1, 9, 11, 12, 15],
      environment: [1, 2],
    },
    {
      id: 3,
      icon: <AcceptedReportIcon />,
      name: "Accepted Requests Report",
      link: ROUTES.ACCEPTED_REQUESTS_REPORT,
      roles: [1, 9, 11],
      environment: [1, 2],
    },
    {
      id: 4,
      icon: <MerchantSalesRequestsIcon />,
      name: "Merchant Sales Requests",
      link: ROUTES.MERCHANT_SALES_REQUESTS,
      roles: [1, 9],
      environment: [1, 2],
    },
    {
      id: 5,
      icon: <SalesRequestsReturnsIcon />,
      name: "Sales Requests Returns",
      link: ROUTES.SALES_REQUESTS_RETURNS,
      roles: [1, 9],
      environment: [1, 2],
    },
    {
      id: 6,
      icon: <MerchantPeriodsIcon />,
      name: "Merchant Periods",
      link: ROUTES.MERCHANT_PERIODS,
      roles: [1, 9],
      environment: [1, 2],
    },
    {
      id: 7,
      icon: <MerchantScrapTypesIcon />,
      name: "Merchant Scrap Types",
      link: ROUTES.MERCHANT_SCRAP_TYPES,
      roles: [1, 9],
      environment: [1, 2],
    },
    {
      id: 8,
      icon: <CourierReportIcon />,
      name: "Courier Report",
      link: ROUTES.COURIER_REPORT,
      roles: [1, 9, 11],
      environment: [1, 2],
    },
    {
      id: 9,
      icon: <DailyCouriersIcon />,
      name: "Daily Couriers",
      link: ROUTES.DAILY_COURIES,
      roles: [1, 9, 10, 14],
      environment: [1, 2],
    },
    {
      id: 36,
      icon: <CourierDistrictsIcon />,
      name: "Courier Districts",
      link: ROUTES.COURIER_DISTRICTS,
      roles: [1, 9],
      environment: [1, 2],
    },
    {
      id: 10,
      icon: <SocialOrdersIcon />,
      name: "Social Orders",
      link: ROUTES.SOCIAL_ORDERS,
      roles: [1, 9, 10, 12, 14, 15],
      environment: [1, 2],
    },
    {
      id: 11,
      icon: <RedeemPointsIcon />,
      name: "Redeem Points Requests",
      link: ROUTES.REDEEM_POINTS_REQUESTS,
      roles: [1, 9, 12, 15],
      environment: [1, 2],
    },
    {
      id: 12,
      icon: <SupplyingTypesIcon />,
      name: "Supplying Types",
      link: ROUTES.SUPPLYING_TYPES,
      roles: [1, 9],
      environment: [1, 2],
    },
    {
      id: 13,
      icon: <GovernmentIcon />,
      name: "Governments",
      link: ROUTES.GOVERNMENT,
      roles: [1, 9],
      environment: [1, 2],
    },
    {
      id: 14,
      icon: <CityIcon />,
      name: "Areas",
      link: ROUTES.CTIY,
      roles: [1, 9],
      environment: [1, 2],
    },
    {
      id: 15,
      icon: <CourierIcon />,
      name: "Couriers",
      link: ROUTES.COURIER,
      roles: [1, 9],
      environment: [1, 2],
    },
    {
      id: 16,
      icon: <HomeBannerIcon />,
      name: "Home Banner",
      link: ROUTES.HOME_BANNER,
      roles: [1, 9],
      environment: [1, 2],
    },
    {
      id: 17,
      icon: <StoresIcon />,
      name: "Stores",
      link: ROUTES.STORES,
      roles: [1, 9],
      environment: [1, 2],
    },
    {
      id: 18,
      icon: <DoDontImageIcon />,
      name: "Do/Don't Images",
      link: ROUTES.DO_DONT_IMAGES,
      roles: [1, 9],
      environment: [1, 2],
    },
    {
      id: 19,
      icon: <MarketplaceBannerIcon />,
      name: "Marketplace Banner",
      link: ROUTES.MARKETPLACE_BANNER,
      roles: [1, 9],
      environment: [1, 2],
    },
    {
      id: 20,
      icon: <StoreCategoriesIcon />,
      name: "Marketplace Categories",
      link: ROUTES.STORE_CATEGORIES,
      roles: [1, 9],
      environment: [1, 2],
    },
    {
      id: 21,
      icon: <StoreSubcategoriesIcon />,
      name: "Marketplace Subcategories",
      link: ROUTES.STORE_SUBCATEGORIES,
      roles: [1, 9],
      environment: [1, 2],
    },
    {
      id: 22,
      icon: <StoreItemsIcon />,
      name: "Marketplace Products",
      link: ROUTES.STORE_PRODUCTS,
      roles: [1, 9],
      environment: [1, 2],
    },
    {
      id: 23,
      icon: <PartnersIcon />,
      name: "Partners",
      link: ROUTES.PARTNERS,
      roles: [1, 9],
      environment: [1],
    },
    {
      id: 24,
      icon: <PartnershipIcon />,
      name: "Partnership",
      link: ROUTES.PARTNERSHIP,
      roles: [1, 9, 14],
      environment: [1, 2],
    },
    // {
    //   icon: <DefineAreaIcon />,
    //   name: "Define Area",
    //   link: "define-area",
    //   roles: [1, 9],
    //   environment: [1, 2],
    // },
    {
      id: 25,
      icon: <NotificationIcon />,
      name: "Notifications",
      link: ROUTES.NOTIFICATION,
      roles: [1, 9],
      environment: [1, 2],
    },
    {
      id: 26,
      icon: <ChatIcon />,
      name: "Big Scrappers Chat",
      link: ROUTES.CHAT_SCRAPPERS,
      roles: [1, 9, 15],
      environment: [1, 2],
    },
    {
      id: 27,
      icon: <ScrappersChatIcon />,
      name: "Green Scouts Chat",
      link: ROUTES.CHAT_SCOUTS,
      roles: [1, 9, 16],
      environment: [1, 2],
    },
    {
      id: 28,
      icon: <SettingsIcon />,
      name: "Settings",
      link: ROUTES.SETTINGS,
      roles: [1, 9],
      environment: [1, 2],
    },
    {
      id: 29,
      icon: <TypesIcon />,
      name: "Types",
      link: ROUTES.CATEGORIES,
      roles: [1, 7, 9],
      environment: [],
    },
    {
      id: 30,
      icon: <SalesRequestsIcon />,
      name: "Scrappers Sales Requests",
      link: "scrappers-sales-requests",
      roles: [1, 7, 9],
      environment: [],
    },
    {
      id: 31,
      icon: <DealersChecksIcon />,
      name: "Scrappers' Checks",
      link: "scrappers-checks",
      roles: [1, 9],
      environment: [],
    },
    {
      id: 32,
      icon: <ScrappersSalesRequestsIcon />,
      name: "Robabekia Sales Requests",
      link: "robabekia-sales-requests",
      roles: [1, 7, 9],
      environment: [],
    },
    {
      id: 33,
      icon: <ItemsIcon />,
      name: "Items",
      link: "items",
      roles: [1],
      environment: [],
    },
    {
      id: 34,
      icon: <AllOrdersIcon />,
      name: "All Orders",
      link: "orders",
      roles: [1],
      environment: [],
    },
    {
      id: 35,
      icon: <PendingOrdersIcon />,
      name: "Pending Orders",
      link: "pending-orders",
      roles: [1],
      environment: [],
    },
  ];

  const handleCatOnClick = ({ link }) => {
    window.scrollTo(0, 0);
    dispatch(updateCurrentPath(link));
    navigate(`/${link}`);
    if (link !== "social-orders") {
      localStorage.setItem("currentPage", 1);
    }
  };

  useEffect(() => {
    setActiveTab(location.pathname.slice(1));
  }, [location]);

  useEffect(() => {
    if (adminRoles?.includes(1) || adminRoles?.includes(9)) {
      updateChatNotifications()
      updateChatNotifications(true)
    } else if (adminRoles?.includes(16)) {
      updateChatNotifications(true)
    } else if (adminRoles?.includes(15)) {
      updateChatNotifications()
    }
  }, [adminRoles])

  return (
    <div className="sidebar d-flex flex-column align-items-center align-items-md-stretch">
      <div className="logo mx-auto">
        <img
          src={logo}
          alt="Scraption logo"
          className="d-block mx-auto img-fluid"
        />
      </div>
      <div className="account mb-4">
        <div className="details">
          <div className="admin">Welcome,</div>
          <div className="account-name">{`${authorization?.fullName}`}</div>
        </div>
        <div className="logout ">
          <Button onClick={handleLogout} className="p-0 border-0 bg-transparent outline-0">
            <img src={logoutIcon} alt="" />
          </Button>
        </div>
      </div>
      <div className="list">
        {categoriesData?.map((item, index) => {
          if (adminRoles?.some((role) => item?.roles?.includes(role))) {
            if (environment === "test" && item?.environment?.includes(1)) {
              return (
                <ItemCategory
                  item={item}
                  activeTab={activeTab}
                  onClick={() => handleCatOnClick(item)}
                  key={item.id}
                  counter={item.id === 26 ? chatNotifications : item.id === 27 ? greenScoutsNotifications : ""}
                />
              );
            } else if (
              environment === "live" &&
              item?.environment?.includes(2)
            ) {
              return (
                <ItemCategory
                  item={item}
                  activeTab={activeTab}
                  onClick={() => handleCatOnClick(item)}
                  key={item.id}
                  counter={item.id === 26 ? chatNotifications : item.id === 27 ? greenScoutsNotifications : ""}
                />
              );
            } else {
              return null;
            }
          }
          return null;
        })}
      </div>
    </div>
  );
}

export default SideBar;

const ItemCategory = ({ item, activeTab, onClick, counter }) => (
  <div
    role="button"
    onClick={onClick}
    className={`category position-relative ${activeTab === item?.link && "selected"}`}
  >
    <div className="d-flex">
      <div className="position-relative">
        <div className="cat-icon">{item.icon}</div>
        {counter ? (
          <span className="sidebar__counter">{counter > 99 ? "99+" : counter}</span>
        ) : (
          ""
        )}
      </div>
      <div className="category-name">{item.name}</div>
    </div>
  </div>
);
