import { useEffect, useRef, useState } from "react";
import { Button, Form } from "antd";
import autoAnimate from "@formkit/auto-animate";
import { ArrowLeftOutlined, ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import { listenToChatRoomsWithPagiantion } from "apis/firebaseService";

// components
import ChatRoom from "./ChatRoom";
import RoomsSearch from "./RoomsSearch";

const ChatsAside = ({
    activeRoom,
    onActiveChange,
    onUnsubscribeActiveChat,
    scoutsChat
}) => {
    const [chatRooms, setChatRooms] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [previousPage, setPreviousPage] = useState(0);
    const [lastDoc, setLastDoc] = useState(undefined);
    const [renderMe, setRenderMe] = useState(false);
    const [filteredData, setFilteredData] = useState(null);
    const [hasMoreData, setHasMoreData] = useState(true);
    const parent = useRef(null);
    const [form] = Form.useForm();
    let unsubscribeRef = useRef();

    const getLastDoc = (prevPage, currPage) => {
        let doc = undefined;
        if (prevPage < currPage) {
            doc = chatRooms[chatRooms.length - 1].docSnapShot
        } else if (prevPage > currPage) {
            doc = chatRooms[0].docSnapShot
        }
        return doc;
    }

    useEffect(() => {
        if (unsubscribeRef?.current) {
            unsubscribeRef.current()
        }
        let doc = searchValue && previousPage === 0 ? undefined : lastDoc
        unsubscribeRef.current = listenToChatRoomsWithPagiantion(
            (rooms) => {
                if (
                    // (rooms.length === chatRooms.length && rooms[0]?.id === chatRooms[0]?.id) ||
                    rooms.length % 10 !== 0 ||
                    rooms.length === 0
                ) {
                    setHasMoreData(false)
                } else {
                    setHasMoreData(true)
                }
                onUnsubscribeActiveChat(false)
                if (rooms.length === 0 && currentPage !== 1) {
                    return
                }
                setFilteredData(() => rooms)
                setChatRooms(() => rooms)
            },
            doc,
            previousPage < currentPage,
            searchValue,
            scoutsChat
        )
        return () => {
            unsubscribeRef.current()
        }
    }, [lastDoc, renderMe, scoutsChat])

    useEffect(() => {
        setPreviousPage(0)
        setCurrentPage(1)
        setLastDoc(undefined)
        if (!lastDoc) {
            setRenderMe(!renderMe)
        }
    }, [searchValue]);

    useEffect(() => {
        parent.current && autoAnimate(parent.current)
    }, [parent, parent.current]);

    return (
        <div className="chat__rooms d-flex flex-column gap-2">
            {chatRooms?.length || searchValue ? (
                <div className="chat__rooms__aside-search">
                    <RoomsSearch
                        placeholder="Search mobile number..."
                        form={form}
                        searchValue={searchValue}
                        onSearch={(value) => {
                            onUnsubscribeActiveChat(true)
                            setFilteredData(null)
                            setSearchValue(() => value)
                        }}
                    />
                </div>
            ) : (
                ""
            )}
            <ul dir="rtl" id={"scrollableDiv"} className="chat__rooms__holder flex-fill">
                <div dir="ltr" ref={parent}>
                    {filteredData ? (
                        filteredData.length > 0 ? (
                            filteredData.map((room) => (
                                <ChatRoom
                                    key={room.id}
                                    data={room}
                                    searchValue={searchValue}
                                    active={activeRoom === room.id}
                                    unreadNotification={room.unReadMessagesCountFromUser > 0}
                                    onActiveChange={onActiveChange}
                                />
                            ))
                        ) : searchValue ? (
                            <p className="text-center">No Result!</p>
                        ) : (
                            ""
                        )
                    ) : (
                        <div className="d-flex justify-content-center py-3">
                            <LoadingOutlined />
                        </div>
                    )}
                </div>
            </ul>
            {chatRooms?.length ? (
                <div className="d-flex justify-content-center align-items-center gap-2">
                    <Button
                        htmlType="button"
                        disabled={currentPage === 1 || chatRooms?.length === 0}
                        onClick={() => {
                            if (currentPage === 1 || chatRooms?.length === 0) return;
                            setFilteredData(null)
                            setCurrentPage(currentPage - 1)
                            setPreviousPage(currentPage)
                            onUnsubscribeActiveChat(true)
                            const lastDoc = getLastDoc(currentPage, currentPage - 1)
                            setLastDoc(lastDoc)
                        }}
                        className="d-block bg-transparent h-auto"
                    >
                        <ArrowLeftOutlined />
                    </Button>
                    <Button
                        htmlType="button"
                        onClick={() => {
                            if (!hasMoreData) return;
                            setFilteredData(null)
                            setCurrentPage(currentPage + 1)
                            setPreviousPage(currentPage)
                            onUnsubscribeActiveChat(true)
                            const lastDoc = getLastDoc(currentPage, currentPage + 1)
                            setLastDoc(lastDoc)
                        }}
                        disabled={!hasMoreData}
                        className="d-block bg-transparent h-auto"
                    >
                        <ArrowRightOutlined />
                    </Button>
                </div>
            ) : (
                ""
            )}
        </div>
    );
}

export default ChatsAside;