import { useState, useEffect } from "react";
import { Row, Space, Modal, Form, Select, DatePicker } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { toastError, toastSuccess } from "../../helpers/toasters";
import {
    assignOrdersToDistrict,
    getCourierDistrictsForList,
    getGovernmentsForList
} from "../../apis/Network";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";

const AssignToDistrictModal = ({
    open,
    onCancel,
    subHeader,
    onSubmit,
    selectedRows,
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [submittable, setSubmittable] = useState(false);
    const [districts, setDistricts] = useState(null);
    // const [districts, setDistricts] = useState([{ label: "-", value: -1 }]);
    const [unassignDistrict, setUnassignDistrict] = useState(false);
    const [governments, setGovernments] = useState(null);
    const [selectedGovernment, setSelectedGovernment] = useState("");
    const [form] = Form.useForm();
    const values = Form.useWatch([], form);
    const initialValues = {
        government: undefined,
        district: undefined,
        assigningDate: "",
    };

    const handleFinish = (values) => {
        if (submitting) return;
        setSubmitting(true);
        let dayjsDate, formattedDate;
        if (!unassignDistrict) {
            dayjsDate = dayjs(values.assigningDate, "D MMMM YYYY");
            formattedDate = dayjsDate.format("YYYY-MM-DDTHH:mm:ss");
        }
        const newFormattedData = selectedRows.map((item) => {
            return {
                assiginingDate: unassignDistrict ? item.assiginingDate : formattedDate,
                requestId: item.requestId,
                note: item.note,
            };
        });
        const payload = {
            districtId: unassignDistrict ? null : values.district,
            userAssignList: newFormattedData,
        };

        assignOrdersToDistrict(
            payload,
            (res) => {
                setSubmitting(false);
                if (res.success) {
                    toastSuccess(res.message);
                    onSubmit();
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setSubmitting(false);
                toastError(res.message);
            }
        );
    }

    useEffect(() => {
        if (selectedGovernment) {
            getCourierDistrictsForList(
                selectedGovernment,
                (res) => {
                    if (res.success) {
                        const options = [];
                        res.data.forEach((district) => {
                            const obj = {
                                label: district.name,
                                value: district.id,
                            };
                            options.push(obj);
                        });
                        // options.unshift({ label: "-", value: -1 })
                        setDistricts(options);
                    } else {
                        toastError(res.message);
                    }
                },
                (res) => {
                    toastError(res?.data?.error);
                }
            );
        }
    }, [selectedGovernment]);

    useEffect(() => {
        getGovernmentsForList(
            (res) => {
                if (res.success) {
                    const optionsArr = res.data.map((ele) => {
                        return {
                            label: ele.name,
                            value: ele.id,
                        };
                    });
                    setGovernments(optionsArr);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                toastError(res?.data?.error);
            }
        );
    }, []);

    useEffect(() => {
        form
            .validateFields({
                validateOnly: true,
            })
            .then(
                () => {
                    setSubmittable(true);
                },
                () => {
                    setSubmittable(false);
                }
            );
    }, [values, form]);

    return (
        <Modal
            title={subHeader}
            open={open}
            className="msg-modal"
            footer={false}
            closable={false}
            centered
            destroyOnClose
            onCancel={onCancel}
        >
            <Form
                form={form}
                className="edit-actor__content pt-3"
                onFinish={handleFinish}
                initialValues={initialValues}
            >
                <div className="new-actor__name new-category mb-5">
                    <label className="users__parent-info-label mb-2 fw-bold" htmlFor="">
                        Government
                    </label>
                    <Form.Item
                        className="new-category__form-item"
                        name="government"
                        rules={unassignDistrict ? (
                            []
                        ) : (
                            [
                                {
                                    required: true,
                                    message: "Please, select government",
                                },
                            ]
                        )}
                    >
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            onChange={(value) => {
                                setSelectedGovernment(value)
                                form.resetFields(["district"])
                                setDistricts(null)
                            }}
                            placeholder="Select government"
                            loading={governments ? false : true}
                            options={governments ? governments : []}
                            getPopupContainer={(trigger) => trigger.parentElement}
                        />
                    </Form.Item>
                </div>
                <div className="new-actor__name new-category mb-5">
                    <label className="users__parent-info-label mb-2 fw-bold" htmlFor="">
                        District
                    </label>
                    <Form.Item
                        className="new-category__form-item"
                        name="district"
                        rules={[
                            {
                                required: true,
                                message: "Please, select district",
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            onChange={(value) => {
                                if (value === -1) {
                                    setUnassignDistrict(true)
                                } else {
                                    setUnassignDistrict(false)
                                }
                            }}
                            placeholder="Select district"
                            loading={districts || !selectedGovernment ? false : true}
                            options={districts ? districts : []}
                            getPopupContainer={(trigger) => trigger.parentElement}
                        />
                    </Form.Item>
                </div>
                <div className="new-actor__name new-category mb-5">
                    <label className="users__parent-info-label mb-2 fw-bold" htmlFor="">
                        Assign Date
                    </label>
                    <Form.Item
                        className="new-category__form-item social-orders__date-picker-holder"
                        name="assigningDate"
                        rules={unassignDistrict ? (
                            []
                        ) : (
                            [
                                {
                                    required: true,
                                    message: "Please, select a date",
                                },
                            ]
                        )}
                    >
                        <DatePicker
                            className="w-100"
                            showNow={false}
                            showSecond={false}
                            format="D MMMM YYYY"
                            disabledDate={(current) => moment().add(-1, "days") >= current}
                            onKeyDown={(e) => e.preventDefault()}
                            getPopupContainer={(triggerNode) => {
                                return triggerNode.parentNode;
                            }}
                        />
                    </Form.Item>
                </div>
                <Row className="mrg-ys-20 mrg-x-auto mt-2 justify-content-center">
                    <Space size={20}>
                        <SecondaryButton buttonName={"Cancel"} onClick={onCancel} />
                        <PrimaryButton
                            buttonName={"Save"}
                            loading={submitting}
                            disableMe={!submittable}
                            type="submit"
                        />
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
};

export default AssignToDistrictModal;
