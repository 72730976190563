import { useState, useEffect } from "react";
import { Row, Space, Modal, Form, Select, DatePicker } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { toastError, toastSuccess } from "../../helpers/toasters";
import { assignCourierToDistrict, getActiveCouriers } from "../../apis/Network";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";

const AssignToCourierModal = ({
  open,
  rowData,
  subHeader,
  onCancel,
  onSubmit,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [submittable, setSubmittable] = useState(false);
  const [activeCouriers, setActiveCouriers] = useState(null);
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const initialValues = {
    courier: undefined,
    assigningDate: "",
  };

  const handleFinish = (values) => {
    if (submitting) return;
    setSubmitting(true);

    const dayjsDate = dayjs(values.assigningDate, "D MMMM YYYY");
    const formattedDate = dayjsDate.format("YYYY-MM-DDTHH:mm:ss");

    const payload = {
      districtId: rowData.id,
      assignDate: formattedDate,
      courierId: values.courier
    };
    assignCourierToDistrict(
      payload,
      (res) => {
        setSubmitting(false);
        if (res.success) {
          toastSuccess(res.message);
          onSubmit();
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setSubmitting(false);
        toastError(res?.data?.error);
      }
    );
  }

  useEffect(() => {
    if (rowData?.courierId) {
      form.setFieldValue("courier", rowData.courierId);
      form.setFieldValue(
        "assigningDate",
        dayjs(rowData.assigningDate, "D MMMM YYYY")
      );
    }
  }, [rowData, form]);

  useEffect(() => {
    getActiveCouriers(
      (res) => {
        if (res.success) {
          const options = [];
          res.data.forEach((courier) => {
            const obj = {
              label: courier.userName,
              value: courier.id,
            };
            options.push(obj);
          });
          setActiveCouriers(options);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        toastError(res.message);
      }
    );
  }, []);

  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
  }, [values, form]);

  return (
    <Modal
      title={subHeader}
      open={open}
      className="msg-modal"
      footer={false}
      closable={false}
      centered
      destroyOnClose
      onCancel={onCancel}
    >
      <Form
        form={form}
        className="edit-actor__content pt-3"
        onFinish={handleFinish}
        initialValues={initialValues}
      >
        <div className="new-actor__name new-category mb-5">
          <label className="users__parent-info-label mb-2 fw-bold" htmlFor="">
            Courier
          </label>
          <Form.Item
            className="new-category__form-item"
            name="courier"
            rules={[
              {
                required: true,
                message: "Please, select courier",
              },
            ]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              placeholder="Select courier"
              loading={activeCouriers ? false : true}
              options={activeCouriers ? activeCouriers : []}
              getPopupContainer={(trigger) => trigger.parentElement}
            />
          </Form.Item>
        </div>
        <div className="new-actor__name new-category mb-5">
          <label className="users__parent-info-label mb-2 fw-bold" htmlFor="">
            Assign Date
          </label>
          <Form.Item
            className="new-category__form-item social-orders__date-picker-holder"
            name="assigningDate"
            rules={[
              {
                required: true,
                message: "Please, select a date",
              },
            ]}
          >
            <DatePicker
              className="w-100"
              showNow={false}
              showSecond={false}
              format="D MMMM YYYY"
              disabledDate={(current) => moment().add(-1, "days") >= current}
              onKeyDown={(e) => e.preventDefault()}
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
            />
          </Form.Item>
        </div>
        <Row className="mrg-ys-20 mrg-x-auto mt-2 justify-content-center">
          <Space size={20}>
            <SecondaryButton buttonName={"Cancel"} onClick={onCancel} />
            <PrimaryButton
              buttonName={"Save"}
              loading={submitting}
              disableMe={!submittable}
              type="submit"
            />
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

export default AssignToCourierModal;
