import { useEffect, useRef, useState } from "react";
import { Alert, Button, Form, Input, Progress } from "antd";
import { useSelector } from "react-redux";
import autoAnimate from "@formkit/auto-animate";
import dayjs from "dayjs";
import MicRecorder from "mic-recorder-to-mp3";
// import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
import { doc, setDoc, increment, arrayUnion, getDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import db, { FBStorage } from "apis/firebase";
import { getCollectionName } from "apis/firebaseService";
import { toastError } from "helpers/toasters";
import { theFirstEncounteredLetterIsArabic } from "helpers/RegexPatterns";
import { convertBytesToSize } from "helpers/helpfulFunctions";

// assets
import sendIcon from "assets/icons/send.svg";
import recordMicIcon from "assets/icons/record-mic.svg";
import attachIcon from "assets/icons/attach-pin.svg";

// components
import UploadOptions from "./UploadOptions";
import PreviewUploadedImages from "./PreviewUploadedImages";
import PreviewUploadedVideo from "./PreviewUploadedVideo";
import PreivewUploadedDocuments from "./PreviewUploadedDocuments";
import VoiceNoteRecord from "./VoiceNoteRecord";

const ChatFooter = ({ room, scoutsChat }) => {
  const [showUploadOptions, setShowUploadOptions] = useState(false);
  const [invalidInput, setInvalidInput] = useState(true);
  const [textInput, setTextInput] = useState("");
  const [uploadedImagesList, setUploadedImagesList] = useState([]);
  const [uploadedDocumentsList, setUploadedDocumentsList] = useState([]);
  const [uploadedVideo, setUploadedVideo] = useState("");
  const [uploadingMedia, setUploadingMedia] = useState(false);
  const [uploadingProgress, setUploadingProgress] = useState(5);
  const [errorMSG, setErrorMSG] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [Mp3Recorder] = useState(new MicRecorder({ bitRate: 128 }));
  const { id, fullName } = useSelector((state) => state.auth.authorization);
  const parent = useRef(null);
  const [form] = Form.useForm();
  const initialValues = { message: "" };

  const validateInput = (_, value) => {
    setTextInput(() => value);
    if (!value || value.trim().length === 0) {
      setInvalidInput(true);
    } else {
      setInvalidInput(false);
    }
    return Promise.resolve();
  };

  const startRecording = () => {
    Mp3Recorder.start()
      .then(() => {
        setIsRecording(true);
      })
      .catch((e) => console.error(e));
  };

  const handelSubmitAudio = () => {
    if (uploadingMedia) return;
    setUploadingMedia(true);
    setIsRecording(false);
    Mp3Recorder.stop()
      .getMp3()
      .then(async ([_, blob]) => {
        try {
          const timestamp = dayjs().format("YYYY-MM-DDTHH:mm:ssZZ");
          const progressStep = 95;
          const blobAsFile = new File([blob], "record.mp3", {
            type: blob.type,
          });
          const messageObj = await uploadMedia(
            blobAsFile,
            "records",
            3,
            timestamp
          );
          setUploadingProgress((progress) => progress + progressStep);
          await sendToFB(messageObj);
          setUploadingMedia(false);
          setUploadingProgress(() => 0);
        } catch (error) {
          console.log(error);
        }
      })
      .catch((e) => console.log(e));
  };

  const uploadFiles = async (files, type) => {
    setShowUploadOptions(false);
    if (type === "documents") {
      setUploadedDocumentsList([]);
    } else if (type === "images") {
      setUploadedImagesList([]);
    } else if (type === "video") {
      setUploadedVideo("");
    }
    if (uploadingMedia) return;
    setUploadingMedia(true);
    const timestamp = dayjs().format("YYYY-MM-DDTHH:mm:ssZZ");
    const progressStep = 100 / files.length;
    for (let i = 0; i < files.length; i++) {
      await (async (file, indx, arr) => {
        const docObj = file.originFileObj;
        let path = "";
        let fileType = 0;
        if (file.type.startsWith("image")) {
          fileType = 2;
          path = "images";
        } else if (file.type.startsWith("video")) {
          fileType = 5;
          path = "videos";
        } else if (file.type.startsWith("audio")) {
          fileType = 3;
          path = "audios";
        } else {
          fileType = 4;
          path = "files";
        }
        const messageObj = await uploadMedia(docObj, path, fileType, timestamp);
        setUploadingProgress((progress) => progress + progressStep);
        await sendToFB(messageObj);
        if (indx === arr.length - 1) {
          setUploadingMedia(false);
          setUploadingProgress(() => 0);
          if (type === "documents") {
            setUploadedDocumentsList([]);
          } else if (type === "images") {
            setUploadedImagesList([]);
          } else if (type === "video") {
            setUploadedVideo("");
          }
        }
      })(files[i], i, files);
    }
  };

  const uploadMedia = async (fileObj, folder, type, timestamp) => {
    try {
      const storageRef = ref(
        FBStorage,
        `${folder}/${timestamp}-${fileObj.name}`
      );
      await uploadBytes(storageRef, fileObj);
      const fileURL = await getDownloadURL(storageRef);
      const size = convertBytesToSize(fileObj.size);
      const messageObj = {
        // id: uuidv4(),
        message: fileURL,
        mediaName: fileObj.name,
        size,
        documentExtension: fileObj.name.split(".").pop().toLowerCase(),
        date: timestamp,
        type,
        adminId: id,
        adminName: fullName,
      };
      return messageObj;
    } catch (error) {
      console.log(error);
      toastError(error);
    }
  };
 
  const sendToFB = async (messageObj) => {
    // check if last message was sent today or before
    try {
    const collectionName = getCollectionName(scoutsChat);
      const docRef = doc(db, collectionName, room);
      const docSnap = await getDoc(docRef);
      const messages = docSnap.data().messages;
      const lastDayObj = messages[messages.length - 1];
      const now = moment();
      const end = moment(lastDayObj.date, "YYYY-MM-DDTHH:mm:ssZZ");
      const wasLastMessageSentToday = end.isSame(now, "d");
      const payload = {
        unReadMessagesCountFromAdmin: increment(1),
        lastMessageDate: messageObj.date,
        lastMessageType: messageObj.type,
        lastMessage: messageObj.message,
      };
      if (wasLastMessageSentToday) {
        const updatedMessages = structuredClone(messages);
        updatedMessages[messages.length - 1].messages.push(messageObj);
        payload.messages = updatedMessages;
      } else {
        payload.messages = arrayUnion({
          date: messageObj.date,
          messages: [messageObj],
        });
      }
      await setDoc(doc(db, collectionName, room), payload, {
        merge: true,
      }).catch((error) => {
        console.log(error);
        toastError(error);
      });
    } catch (error) {
      console.log(error);
      toastError(error);
    }
  };

  const handleSubmit = async (values) => {
    if (invalidInput) return;
    setInvalidInput(true);
    form.resetFields();
    const msgDate = dayjs().format("YYYY-MM-DDTHH:mm:ssZZ");
    const msgContent = values.message.trim();
    const messageObj = {
      message: msgContent,
      date: msgDate,
      type: 1,
      adminId: id,
      adminName: fullName,
    };
    await sendToFB(messageObj, msgContent);
  };

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent, parent.current]);

  return (
    <div className="position-relative">
      <UploadOptions
        show={showUploadOptions}
        uploadingMedia={uploadingMedia}
        uploadedImagesList={uploadedImagesList}
        uploadedDocumentsList={uploadedDocumentsList}
        onUploadingProgressChange={(value) => {
          setUploadingProgress(() => value);
        }}
        onUploadingImages={(images) => {
          setUploadedImagesList(() => images);
        }}
        onUploadingDocuments={(docs) => {
          setUploadedDocumentsList(() => docs);
        }}
        onUploadingVideo={(video) => {
          setUploadedVideo(video);
        }}
        onErrorMSG={(msg) => setErrorMSG(msg)}
      />
      {errorMSG ? (
        <Alert
          className="chat__chat-holder__footer__upload-error-alert"
          message={errorMSG}
          afterClose={() => setErrorMSG("")}
          type="error"
          closable
        />
      ) : (
        ""
      )}
      {uploadingMedia ? (
        <div className="chat__chat-holder__footer__progress-holder">
          <Progress
            showInfo={false}
            size={"small"}
            className="lh-1 d-flex"
            percent={uploadingProgress}
            strokeColor={{
              "0%": "#61d03b",
              "100%": "#0f3264",
            }}
          />
        </div>
      ) : (
        ""
      )}
      <div ref={parent}>
        {isRecording ? (
          <VoiceNoteRecord
            onSubmit={handelSubmitAudio}
            onCancel={() => {
              Mp3Recorder.stop();
              setIsRecording(false);
            }}
          />
        ) : (
          <section className="chat__chat-holder__footer position-relative">
            <Form
              form={form}
              onFinish={handleSubmit}
              initialValues={initialValues}
              className="d-flex align-items-center gap-3 gap-md-4"
            >
              <div className="position-relative flex-fill">
                <Form.Item
                  name="message"
                  rules={[{ validator: validateInput }]}
                  className="d-block mb-0"
                >
                  <Input.TextArea
                    className="d-block w-100 fw-bold fsize-11"
                    placeholder={"Message"}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        form.submit()
                      }
                    }}
                    dir={
                      theFirstEncounteredLetterIsArabic.test(textInput)
                        ? "rtl"
                        : "ltr"
                    }
                  />
                </Form.Item>
                <Button
                  htmlType="button"
                  onClick={() => setShowUploadOptions(!showUploadOptions)}
                  className={`chat__chat-holder__footer__attach-btn d-block position-absolute top-50 translate-middle-y px-1 border-0 outline-none bg-transparent me-2 me-md-3 end-0`}
                >
                  <img src={attachIcon} alt="" className="d-block img-fluid" />
                </Button>
              </div>
              {invalidInput ? (
                <Button
                  htmlType="button"
                  onClick={() => {
                    if (!isRecording) {
                      startRecording();
                    }
                  }}
                  className="chat__chat-holder__footer__mic-btn d-flex justify-content-center align-items-center border-0 outline-none shadow-none bg-transparent p-0"
                >
                  <img
                    src={recordMicIcon}
                    alt="Record icon"
                    className="d-block img-fluid"
                  />
                </Button>
              ) : (
                <Button
                  htmlType="submit"
                  disabled={invalidInput}
                  className="chat__chat-holder__footer__submit-btn d-flex justify-content-center align-items-center border-0 outline-none shadow-none bg-transparent p-0"
                >
                  <img
                    src={sendIcon}
                    alt="Submit icon"
                    className="d-block img-fluid change-direction"
                  />
                </Button>
              )}
            </Form>
          </section>
        )}
      </div>
      {uploadedImagesList?.length ? (
        <PreviewUploadedImages
          files={uploadedImagesList}
          onCancel={() => setUploadedImagesList([])}
          onUpload={() => uploadFiles(uploadedImagesList, "images")}
        />
      ) : (
        ""
      )}
      {uploadedVideo ? (
        <PreviewUploadedVideo
          file={uploadedVideo}
          onCancel={() => setUploadedVideo("")}
          onUpload={() => uploadFiles([uploadedVideo], "video")}
        />
      ) : (
        ""
      )}
      {uploadedDocumentsList?.length ? (
        <PreivewUploadedDocuments
          files={uploadedDocumentsList}
          onCancel={() => setUploadedDocumentsList([])}
          onUpload={() => uploadFiles(uploadedDocumentsList, "documents")}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default ChatFooter;
