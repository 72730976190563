import { useEffect, useRef, useState } from "react";
import { Button, Input, Space, Switch } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { getCourierDistricts } from "../../apis/Network";
import { toastError } from "../../helpers/toasters";

// components
import GridTable from "../SubComponents/GridTable/GridTable";
import MainHeader from "../SubComponents/MainHeader";
import SubHeader from "../SubComponents/SubHeader";
import AddEditModal from "./AddEditModal";
import DeleteModal from "./DeleteModal";
import AssignToCourierModal from "./AssignToCourierModal";
import AssignHistoryModal from "./AssignHistoryModal";

const CourierDistricts = () => {
    const [loading, setLoading] = useState(true);
    const [dataSource, setDataSource] = useState([]);
    const [rowData, setRowData] = useState(null);
    const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isAssignToCourierModalOpen, setIsAssignToCourierModalOpen] = useState(false);
    const [isAssignHistoryModalOpen, setIsAssignHistoryModalOpen] = useState(false);
    const [renderMe, setRenderMe] = useState(false);
    const [mode, setMode] = useState("add");
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);

    const handleCancelModal = () => {
        setIsAssignToCourierModalOpen(false);
        setIsAddEditModalOpen(false);
        setIsDeleteModalOpen(false);
        setIsAssignHistoryModalOpen(false)
        setMode("add");
        setRowData(null);
    };
    const handleSubmit = () => {
        setRenderMe(!renderMe);
        handleCancelModal()
    };
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    ></Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            key: "govenmentName",
            title: "GOVERNMENT",
            dataIndex: "govenmentName",
            ...getColumnSearchProps("govenmentName"),
        },
        {
            key: "nameEn",
            title: "DISTRICT NAME(EN)",
            dataIndex: "nameEn",
            ...getColumnSearchProps("nameEn"),
        },
        {
            key: "nameAr",
            title: "DISTRICT NAME(AR)",
            dataIndex: "nameAr",
            ...getColumnSearchProps("nameAr"),
        },
        {
            key: "isActive",
            title: "STATUS",
            dataIndex: "isActive",
            filters: [
                { text: "Active", value: true },
                { text: "Inactive", value: false },
            ],
            onFilter: (value, record) => record?.isActive === value,
            render: (_, row) => {
                return <Switch checked={row.isActive} style={{ cursor: "default" }} />;
            },
        },
        {
            key: "requestId",
            title: "ACTIONS",
            render: (_, row) => {
                return (
                    <div className="d-flex flex-column row-gap-2">
                        <div className="d-flex gap-2">
                            <Button
                                className="border-0 w-100"
                                onClick={() => {
                                    setMode("edit");
                                    setRowData(row);
                                    setIsAddEditModalOpen(true);
                                }}
                                style={{ backgroundColor: "#589559", color: "white" }}
                            >
                                Edit
                            </Button>
                            <Button
                                type="danger"
                                onClick={() => {
                                    setRowData(row);
                                    setIsDeleteModalOpen(true);
                                }}
                                className="text-white w-100"
                                style={{ backgroundColor: "#E72121", borderColor: "#E72121" }}
                            >
                                Delete
                            </Button>
                        </div>
                        <div className="d-flex gap-2">
                            <Button
                                className="d-block w-100"
                                style={{
                                    backgroundColor: "#176b87",
                                    color: "white",
                                    border: "none",
                                }}
                                onClick={() => {
                                    setRowData(row);
                                    setIsAssignToCourierModalOpen(true);
                                }}
                            >
                                Assign Courier
                            </Button>
                            {row.courierAssignDates.length ? (
                                <Button
                                    className="btn--secondary d-block w-100"
                                    onClick={() => {
                                        setRowData(row);
                                        setIsAssignHistoryModalOpen(true);
                                    }}
                                >
                                    Assign Details
                                </Button>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        getCourierDistricts(
            (res) => {
                setLoading(false);
                if (res.success) {
                    setDataSource(res.data);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setLoading(false);
                toastError(res.message);
            }
        );
    }, [renderMe]);

    return (
        <section className="users">
            <div className="users__all-contents">
                <div className="users__content">
                    <div className="users__usersData">
                        <div className="pb-3 mb-3">
                            <MainHeader
                                title="Courier Districts"
                                buttonName={"Add District"}
                                onButtonClick={() => {
                                    setIsAddEditModalOpen(true);
                                    setMode("add");
                                }}
                            />
                        </div>
                        <div className="users__table grid-table mrg-y-30">
                            <GridTable
                                loading={loading}
                                columns={columns}
                                dataSource={dataSource}
                                rowId={"id"}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {isAddEditModalOpen && (
                <AddEditModal
                    open={isAddEditModalOpen}
                    rowData={rowData}
                    mode={mode}
                    primaryBtnName={mode === "add" ? "Add" : "Save"}
                    subHeader={
                        <SubHeader
                            bgColor="#589559"
                            title={`${mode === "add" ? "Add" : "Edit"} District`}
                            closeButton
                            onCancel={handleCancelModal}
                        />
                    }
                    onSubmit={handleSubmit}
                    onCancel={handleCancelModal}
                />
            )}
            {isDeleteModalOpen && (
                <DeleteModal
                    open={isDeleteModalOpen}
                    rowData={rowData}
                    subHeader={
                        <SubHeader
                            bgColor="#589559"
                            title="Delete District"
                            closeButton
                            onCancel={handleCancelModal}
                        />
                    }
                    onDelete={handleSubmit}
                    onCancel={handleCancelModal}
                />
            )}
            {isAssignToCourierModalOpen && (
                <AssignToCourierModal
                    open={isAssignToCourierModalOpen}
                    rowData={rowData}
                    subHeader={
                        <SubHeader
                            bgColor="#589559"
                            title="Assign Courier To District"
                            closeButton
                            onCancel={handleCancelModal}
                        />
                    }
                    onSubmit={handleSubmit}
                    onCancel={handleCancelModal}
                />
            )}
            {isAssignHistoryModalOpen && (
                <AssignHistoryModal
                    open={isAssignHistoryModalOpen}
                    rowData={rowData}
                    subHeader={
                        <SubHeader
                            bgColor="#589559"
                            title="Assign Details"
                            closeButton
                            onCancel={handleCancelModal}
                        />
                    }
                    onCancel={handleCancelModal}
                />
            )}
        </section>
    );
};

export default CourierDistricts;
